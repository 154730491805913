@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(32, 37, 53);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:autofill {
	background: transparent !important; /* or any other */
}

.post-feed .ql-editor {
	font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.6px !important;

	color: white;
	height: auto;
    border: none !important;
    scrollbar-width: auto;
    scrollbar-color: rgb(145, 154, 172) rgba(0,0,0,0);
    overflow: auto;
}

.ql-editor {
	padding: 10px 10px !important;
	height: auto !important;
}

.ql-container {
	height: auto !important;
	border: none !important;
}

.ql-editor.ql-blank::before {
    color: lightgray !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    letter-spacing: 0.6px !important;
}

.ql-toolbar {
    border: none !important;
}

.ql-toolbar .ql-stroke {
    fill: none  !important;
    stroke: #fff  !important;
}

.ql-toolbar .ql-fill {
    fill: #fff  !important;
    stroke: none  !important;
}

.ql-toolbar .ql-picker {
    color: #fff !important;
}

.ql-toolbar .ql-active .ql-stroke {
	stroke: #06c !important;
	fill: none !important;
}

.ql-toolbar .ql-active .ql-fill {
	stroke: none !important;
	fill: #06c !important;
}